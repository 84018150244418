@import "styles/variables.scss";
.block {
  position: relative;
}
.cookieBtn {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 100001;
  button {
    border: 1px solid $white;
    border-radius: 4px;
    background: inherit;
    color: $white;
    font-size: 12px;
    font-weight: 600;
  }
}
.banner {
  display: flex !important;
  justify-content: center !important;
  height: 60px !important;
}

@media screen and (max-width: 720px) {
  .banner {
    height: 100px !important;
  }
}
